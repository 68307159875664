import React from 'react'
import  loader from '../../icons/loader.svg'
export default function Loading(props) {
    

    return (
        <div className="center">
<img src={loader} alt=""/>
</div>
    )
}
